import roleMap from "../permission/roles.json"

export const getRoles = (roleName) => {
  const user_data = JSON.parse(localStorage.getItem("user_data"))
  const useroleID = user_data?.role_id?._id
    // console.log(roleName,"aa",user_data.role_id,"user_data,role_id")
    let flag;
    for (const role in roleMap) {
      if (roleMap.hasOwnProperty(role)) {
        if(roleMap[role] == useroleID){
         flag =roleName.includes(role) 
        }
      }
    }
    console.log(roleMap,"roleMap")
    console.log(flag,"flag")
    return flag;
  };