import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Input,
  Table,
  Tag,
  Button,
  message,
  Form,
  Row,
  Select,
  DatePicker,
  Col,
  Modal,
  Popconfirm,
  Card,
  TimePicker,
  InputNumber,
  Checkbox,
  Upload,
} from "antd";
import {
  EditOutlined,
  DeleteTwoTone,
  SaveTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";
import Service from "../../service";
import { getResource } from "../../util/getResourcePermission";
import config from "../../permission/config.json"

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const BusinessUnit = () => {
  const [IsModalView2, setIsModalView2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  let [flag, setFlag] = useState(false);
  let [editid, setEditid] = useState();
  const [edtitext, setEdittext] = useState({});
  const [viewDepartment, setViewDepartment] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [userId, setUserId] = useState({});

  const [searchText, setSearchText] = useState("");
  const [seachEnabled, setSearchEnabled] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const Search = Input.Search;

  const searchRef = useRef();

  const { authUser } = useSelector(({ auth }) => auth);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const openEventModalView2 = () => {
    // setEvent_record(record);
    setIsModalView2(true);
  };

  const closeEventModalView2 = () => {
    // setEvent_record(record);
    form.resetFields();
    setIsModalView2(!IsModalView2);
    // setFilterData([]);
  };

  const resetFields = () => {
    form.resetFields();
  };

  useEffect(() => {
    getBusinessUnitList();
  }, [pagination.current, pagination.pageSize, searchText]);

  const handleSubmit = async (values, e) => {
    // console.log(authUser?._id + "authUser?._id authUser?._id")

    try {
      let name = values?.departmentadd.trim().toLowerCase();
      const reqBody = {
        // org_id: "60e69006c78d482d9347a830",
        businessUnitName: name.charAt(0).toUpperCase() + name.slice(1),
        // user_id: authUser?._id,
      };

      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.businessUnitAdd,
        body: reqBody,
      });

      if (response.data.status == 200) {
        // props.history.push("/employee-master");
        message.success(response.data.message);
        form.resetFields();
        getBusinessUnitList();
        closeEventModalView2();
      } else {
        message.error(response.data.message);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      // dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  const deleteBusinessUnit = async (id) => {
    try {
      const reqBody = {
        user_id: authUser?._id,
      };
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.businessUnitDelete + "/" + id,
        body: reqBody,
      });

      if (response.data.status === 200) {
        getBusinessUnitList();
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = (value) => {
    setSearchText(value);

    setPagination({ ...pagination, current: 1 });
  };
  const resetSearchFilter = (e) => {
    const keyCode = e && e.keyCode ? e.keyCode : e;
    switch (keyCode) {
      case 8:
        if (searchRef.current.state?.value?.length <= 1 && seachEnabled) {
          searchRef.current.state.value = "";
          setSearchText("");
          setSearchEnabled(false);
        }
        break;
      case 46:
        if (searchRef.current.state?.value?.length <= 1 && seachEnabled) {
          searchRef.current.state.value = "";
          setSearchText("");
          setSearchEnabled(false);
        }
        break;
      default:
        break;
    }
  };

  const handlechange = (e) => {
    let department = e.target.value;
    setEdittext({ ...edtitext, department: department });
  };

  const handleEdit = async (id) => {
    // console.log(value, "checkcehck")
    try {
      let name = edtitext?.department.trim().toLowerCase();
      dispatch(showAuthLoader());

      const reqBody = {
        businessUnitName: name.charAt(0).toUpperCase() + name.slice(1),
        // user_id: authUser?._id,
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.businessUnitEdit + "/" + id,
        body: reqBody,
      });
      if (response.data.status === 200) {
        message.success(response.data.message);
        getBusinessUnitList();
        setEdittext({});
      } else {
        setEdittext({});
        message.error(response.data.message);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  useEffect(() => {
    form.setFieldsValue(viewDepartment);
  }, [form, viewDepartment]);

  const columns = [
    {
      title: "Business Unit",
      dataIndex: "businessunit",
      key: "businessunit",
      render: (text, record, index) => {
        const position = record?.businessUnitName;
        return record?._id == editid ? (
          <span
            onChange={(value) => {
              handlechange(value);
            }}
            style={{ textTransform: "capitalize" }}
          >
            <Input defaultValue={position} />
          </span>
        ) : (
          <span style={{ textTransform: "capitalize" }}>{position}</span>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            flexwrap: "wrap",
          }}
        >
          {flag == true && editid == record?._id ? (
            <>
              <Button type="link edit">
                <SaveTwoTone
                  style={{ fontSize: "18px" }}
                  onClick={() => {
                    handleEdit(record?._id);
                    setFlag(false);
                    setEditid("");
                  }}
                />
              </Button>
              <Button
                type="link delete"
                title="View"
                onClick={() => setEditid("")}
              >
                <CloseCircleTwoTone style={{ fontSize: "18px" }} />
              </Button>
            </>
          ) : (
            <>
              {getResource(config.RESOURCES.DEPARTMENTS, ["update"]) && (
                <Button type="link edit">
                  <EditOutlined
                    style={{ fontSize: "18px" }}
                    onClick={() => {
                      setEditid(record._id);
                      setFlag(true);
                    }}
                  />
                </Button>
              )}
              {getResource(config.RESOURCES.DEPARTMENTS, ["delete"]) && (
                <Popconfirm
                  title="Are you really want to delete this business unit?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => deleteBusinessUnit(record._id)}
                >
                  <Button type="link delete">
                    <AiOutlineDelete style={{ fontSize: "18px" }} />
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const getBusinessUnitList = useCallback(async (values) => {
    try {
      dispatch(showAuthLoader());

      const reqBody = {
        pageNum: pagination.current,
        pageLimit: pagination.pageSize,
      };

      if (searchText && searchText !== "") {
        reqBody.search = searchText;
        setSearchEnabled(true);
      }

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.businessUnitList,
        body: reqBody,
      });
      // dispatch(hideAuthLoader());
      if (response.data && response.data.data) {
        setDepartmentList(response.data.data);
        setPagination({
          ...pagination,
          total: response.data.metaData.totalDepartments,
          deactivetotal: response.data.metaData.totaldUsers,
        });
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  }, [searchText, pagination.current, pagination.pageSize]);

  const handleTableChange = (page, filters, sorter) => {
    setPagination({ ...pagination, ...page });
  };

  const getFooterDetails = () => {
    return (
      <label>
        Total Records Count is {pagination.total > 0 ? pagination.total : 0}
      </label>
    );
  };

  return (
    <>
      <Card className="employee-card" title="Business Unit">
        {
          getResource(config.RESOURCES.DEPARTMENTS, ["create"]) && (
            <Button
              // disabled
              type="primary"
              className="addleave-btn"
              onClick={() => openEventModalView2()}
            >
              Add
            </Button>
          )
        }

        <Search
          ref={searchRef}
          placeholder="Search..."
          onSearch={onSearch}
          onKeyUp={resetSearchFilter}
          style={{ width: 200 }}
          className="mr2"
        />

        <Table
          columns={columns}
          pagination={{
            showSizeChanger: true,
            ...pagination,
          }}
          footer={getFooterDetails}
          onChange={handleTableChange}
          dataSource={departmentList}
        />
      </Card>

      <Modal
        title="Add Business Unit"
        width={800}
        visible={IsModalView2}
        // okText="Update"
        footer={false}
        onCancel={closeEventModalView2}
      >
        <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
          <div className="add-registration leave_form">
            <Row>
              <Col md={24} sm={24}>
                <Form.Item
                  className="search-row"
                  label="Business Unit"
                  name="departmentadd"
                  rules={[
                    {
                      required: true,
                      message: "Please select Business Unit!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col md={24} sm={24}>
                <Form.Item
                  {...formItemLayout}
                  className="ant-col-xs-24 ant-col-sm-24 leavebtns"
                >
                  <div className="add-leave-button">
                    <Button type="primary" htmlType="submit" id="savebutton">
                      Save
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button type="primary" onClick={resetFields}>
                      Clear
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>

      {isModalOpen && (
        <Modal
          title="Edit Business Unit"
          width={800}
          // okText="Update"
          footer={false}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form {...formItemLayout} form={form}>
            <div className="add-registration leave_form">
              {console.log(viewDepartment, "viewDepartment")}
              <Row>
                <Col md={24} sm={24}>
                  <Form.Item
                    className="search-row"
                    label="Department"
                    name="department"
                    initialValue={viewDepartment?.department_name}
                    rules={[
                      {
                        required: true,
                        message: "Please select Business Unit<T!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={24} sm={24}>
                  <Form.Item
                    {...formItemLayout}
                    className="ant-col-xs-24 ant-col-sm-24 leavebtns"
                  >
                    <div className="add-leave-button">
                      <Button type="primary" htmlType="submit" id="savebutton">
                        Save
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button type="primary" onClick={resetFields}>
                        Clear
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default BusinessUnit;
