import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./resignationflow.css";
import { DatePicker, Space, Input, Card, Form, Select, Row, Col } from "antd";
import { LAST_WORKING_DAY_LIST } from "../../permission/access";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import moment from "moment";
import Service from "../../service";
import { hideAuthLoader, showAuthLoader } from "../../appRedux/actions";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { getResource } from "../../util/getResourcePermission";
import config from "../../permission/config.json";

function ResignationFlow() {
  const dispatch = useDispatch();
  let { authUser } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
  const [holiday, setholiday] = useState([]);
  const onChange = (date, dateString) => {
    setSelectedDate(date);
  };

  const [selDate, setSelDate] = useState(null);

  const OnChangeForRequest = (date, dateString) => {
    console.log("🚀 ~ OnChangeForRequest ~ dateString:", dateString);
    setSelDate(dateString);
  };

  useEffect(() => {
    if (selectedDate) {
      calculateLastDate(selectedDate);
    }
  }, [selectedDate, holiday]);
  useEffect(() => {
    holidayList();
  }, []);

  const [lastDate, setLastDate] = useState(null);

  // const calculateLastDate = (date) => {
  //   const today = new Date(date);
  //   const noticePeriod = 60;

  //   const lastDate = new Date(
  //     today.getTime() + noticePeriod * 24 * 60 * 60 * 1000
  //   );
  //   console.log(lastDate.getDay())
  //   setLastDate(lastDate.toISOString().split("T")[0]);
  // };
  const holidayList = async () => {
    try {
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getholidays,
      });
      if (response.data && response.data.data && response.data.status === 200) {
        const holidays = response.data.data.map((item) =>
          item.holiday_date.slice(0, 10)
        );
        setholiday(holidays);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateLastDate = (date) => {
    const today = moment(date);
    const noticePeriod = 60;
    console.log(holiday, "holidayyysss");
    const lastDate = moment(today).add(noticePeriod, "days");
    while (
      lastDate.day() === 6 ||
      lastDate.day() === 0 ||
      holiday.includes(lastDate.format("YYYY-MM-DD"))
    ) {
      if (lastDate.day() === 6) {
        // Saturday
        lastDate.subtract(1, "days"); // Move back one day to Friday
      } else if (lastDate.day() === 0) {
        // Sunday
        lastDate.subtract(2, "days"); // Move back two days to Friday
      } else {
        // Holiday
        lastDate.subtract(1, "days"); // Move back one day
      }
    }

    setLastDate(lastDate.format("YYYY-MM-DD"));
  };

  const disabledDate = (currentDate) => {
    const presentDate = moment().startOf("day");
    return currentDate && !currentDate.isSame(presentDate, "day");
  };

  const textStyle = {
    margin: "10px 0px",
    width: "70%",
    resize: "none",
    marginLeft: "80px",
  };

  const handleSubmit = async (values) => {
    console.log("🚀 ~ handleSubmit ~ values:", values);
    try {
      const formData = new FormData();
      if (selDate) {
        formData.append(
          "request_last_date",
          moment(values?.request_last_date).format("YYYY-MM-DD")
        );
      }
      formData.append("reason", values?.reason);
      formData.append("comments", values?.comments);

      fileList.forEach((file) => {
        formData.append("attachment", file.originFileObj);
      });

      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.addresign,
        body: formData,
      });
      dispatch(hideAuthLoader());
      if (response.data && response.data.data && response.data.status === 200) {
        message.success(response.data.message);
        form.resetFields();
        setLastDate(null);
      } else {
        form.resetFields();
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileChange = (info) => {
    console.log("🚀 ~ handleFileChange ~ info:", info);

    let fileList = [...info.fileList];
    setFileList(fileList);
  };

  const disabledDateRequested = (current) => {
    return (
      moment(current).startOf("day") <=
      moment().startOf("day").subtract(1, "day")
    );
  };

  return (
    <>
      <Card title="Apply For Resignation">
        <Link to="/resignation-userlist">
          <Button type="primary">My Data</Button>{" "}
        </Link>

        <Form
          className="form-control-group"
          onFinish={handleSubmit}
          form={form}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Date of Resignation"
                name="date_of_resignation"
                className="label-name"
                rules={[
                  {
                    required: true,
                    message: "Please select date!",
                  },
                ]}
                initialValue={moment().startOf("day")}
              >
                <p>{moment().startOf("day").format("YYYY-MM-DD")}</p>
                {/* <DatePicker value={moment().startOf('day')} onChange={onChange} disabledDate={disabledDate} /> */}
              </Form.Item>

              <Form.Item
                label="Last Date As Per Notice Period (60Days)"
                name="last_date_as_per_notice_period"
                className="label-name"
              >
                {lastDate && <p>{lastDate}</p>}
              </Form.Item>

              <Form.Item
                label="Requested Last Date"
                name="request_last_date"
                className="label-name"
              >
                <DatePicker
                  disabledDate={disabledDateRequested}
                  onChange={OnChangeForRequest}
                  style={{ float: "left", width: "50%" }}
                />
                {/* </Space> */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Reason For Resignation"
                name="reason"
                className="label-name reason-resignation-from"
                rules={[
                  {
                    required: true,
                    message: "Please select reason!",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    { value: "Health issue", label: "Health issue" },
                    { value: "Career Changes", label: "Career Changes" },
                    { value: "Travelling", label: "Travelling" },
                    { value: "Abroad", label: "Abroad" },
                    { value: "Higher studies", label: "Higher studies" },
                    {
                      value: "Work related issue",
                      label: "Work related issue",
                    },
                    { value: "Marriage", label: "Marriage" },
                    { value: "Performance issue", label: "Performance issue" },
                    { value: "Ask to Leave", label: "Ask to Leave" },
                    {
                      value: "Better Role & Career progression",
                      label: "Better Role & Career progression",
                    },
                    {
                      value: "Code of Conduct Discipline",
                      label: "Code of Conduct Discipline",
                    },
                    {
                      value: "Dissatisfaction with manager",
                      label: "Dissatisfaction with manager",
                    },
                    {
                      value: "Entrepreneur, New business",
                      label: "Entrepreneur, New business",
                    },
                    {
                      value: "Family Issues & Commitments",
                      label: "Family Issues & Commitments",
                    },
                    {
                      value: "Mis fit for role in Elsner",
                      label: "Mis fit for role in Elsner",
                    },
                    {
                      value: "Relocating to Another City",
                      label: "Relocating to Another City",
                    },
                    { value: "Salary Growth", label: "Salary Growth" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Comments"
                name="comments"
                className="label-name reason-resignation-from"
                rules={[
                  {
                    required: true,
                    message: "Please enter comments!",
                  },
                ]}
              >
                <Input.TextArea
                  autoSize={{
                    minRows: 5,
                    maxRows: 5,
                  }}
                  style={textStyle}
                />
              </Form.Item>

              <Form.Item
                label="Any Attachments"
                name="attachments"
                className="label-name"
              >
                <Upload
                  multiple={false}
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          {
            getResource(config.RESOURCES.SEPARATION,["create"]) && (
              <div style={{ textAlign: "center" }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </div>
            )
          }
        </Form>
      </Card>
    </>
  );
}
export default ResignationFlow;
