import React, { useEffect, useState, useRef } from "react";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  EyeTwoTone,
  InboxOutlined,
  MoreOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Select, TimePicker, Switch, Checkbox } from "antd";
import "./UserProfile.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Form,
  Input,
  Modal,
  Popover,
  message,
  Row,
  Tag,
  Tabs,
  Card,
} from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import ProfileImage from "../../assets/images/default_profile.jpg";
import CelebrationIcon from "../../assets/images/celebration_icon.png";
import EyeIcon from "../../assets/images/eye_icon.png";
import PdfIcon from "../../assets/images/pdf_icon.png";
import JobIcon from "../../assets/images/job_icon1.svg";
import { Link, withRouter } from "react-router-dom";
import Service from "../../service";
import { CiBellOn } from "react-icons/ci";
import { BiCheckbox, BiRefresh } from "react-icons/bi";
import { ImTicket } from "react-icons/im";

import { showAuthLoader, hideAuthLoader } from "../../appRedux/actions/Auth";

import moment from "moment";
import dayjs from "dayjs";
import { notificationAccessBlock } from "../../roles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getRoles } from "../../../src/util/getRoles";
import TabPane from "antd/lib/tabs/TabPane";
// import { useSocketAction } from "../../../../../PMS/client/src/hooks/useSocketAction";
// import { useSocket } from "../../../../../PMS/client/src/context/SocketContext";
// import { Model } from "mongoose";

const UserProfile = (props) => {
  const dispatch = useDispatch();
  const format = "HH:mm";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [dataCount, setCountdata] = useState([]);
  const [visible, setVisible] = useState(false);
  const [flag, setflag] = useState(false);

  //Admin Data Get Tabs Tabpnl2
  const [tggelLeave,settggelLeave]=useState(false)

  const [dataGt, setDataGt] = useState({
    SMTP: { email: "", password: "" },
    working_hours: {
      full_day: "",
      half_day: "",
      break_time: "",
      total_time: "",
    },
    companyTime: {
      startAt: "",
      closeAt: "",
    }, group_email: "",
    probation_duration: '',
    isLeaveAutoApprove: false,
    isWHFAutoApprove: false,
    isRegularizationAutoApprove: false,
    birthDayLeave: tggelLeave,
    // birthdayLeave: {
        // isApplicable: tggelLeave,
        numOfDay: 0
    // }
  });
  //copy start
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const history = useHistory();
  const [emailSetting] = Form.useForm();
  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const [apiDetailSetting] = Form.useForm();
  const [generalSetting] = Form.useForm();
  const [AdminSetting] = Form.useForm();

  // const dispatch = useDispatch();
  // const socket = useSocket();
  // const { emitEvent, listenEvent, showBrowserNotification } = useSocketAction();
  // const user_data=JSON.parse(localStorage.getItem("user_data"))

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  // const [dataCount, setCountdata] = useState([]);
  // const [visible, setVisible] = useState(false);
  // const [visiblePopOver,setIsVisiblePopOver] = useState(false)
  // const [flag, setflag] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationReadData, setNotificationReadData] = useState([]);
  const [activeTab, setActiveTab] = useState("unread");
  const [unReadId, setUnReadId] = useState([]);
  // const { TabPane } = Tabs;
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [apiId, setApiId] = useState("");
  const [logoModeRadio, setLogoModeRadio] = useState();
  const [fileLists, setFileLists] = useState({
    login_logo: null,
    header_logo: null,
    fav_logo: null,
  });
  const attachmentfileRefs = {
    login_logo: useRef(null),
    header_logo: useRef(null),
    fav_logo: useRef(null),
  };
  const [generalApp, setGeneralApp] = useState([]);
  const [generalAdd, setGeneralAdd] = useState([]);
  const [tempurl, setTempUrl] = useState({
    login_logo: null,
    header_logo: null,
    fav_logo: null,
  });

  const headerLogo = localStorage.getItem("headerLogo");

  // const handleTabChange1 = (activeKey) => {
  //   if (activeKey == 2) {
  //     getApiKey();
  //   }
  //   if (activeKey == 3) {
  //     generalSettingApp();
  //   }
  // };
  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = async () => {
    form.resetFields();
    setIsModalOpen1(false);
    await generalSettingApp();
  };

  const dimensionConstraints = {
    login_logo: { width: 250, height: 50 },
    header_logo: { width: 135, height: 25 },
    fav_logo: { width: 16, height: 16 },
  };
  // Helper function to get image dimensions
  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };

  // const getApiKey = async () => {
  //   try {
  //     dispatch(showAuthLoader());
  //     const response = await Service.makeAPICall({
  //       api_url: Service.getApiKey,
  //       methodName: Service.getMethod,
  //     });
  //     if (response?.data && response?.data?.data) {
  //       dispatch(hideAuthLoader());
  //       setApiKey(response?.data?.data?.api_key);
  //       setApiId(response?.data?.data?._id);
  //     }
  //   } catch (error) {
  //     dispatch(hideAuthLoader());
  //     console.log(error, "getMethod error");
  //   }
  // };
  const generalSettingApp = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        api_url: Service.getGeneralSetting + "/" + user_data._id,
        methodName: Service.getMethod,
        options: {
          "content-type": "multipart/form-data",
        },
      });
      if (response?.data && response?.data?.data) {
        dispatch(hideAuthLoader());
        setGeneralApp(response?.data?.data);
        generalSetting.setFieldValue({
          title: response?.data?.data?.title,
        });
        setFileLists({
          fav_logo: response.data.data.fav_icon
            ? {
              name: response.data.data.fav_icon,
              url: response.data.data.fav_icon,
            }
            : null,
          login_logo: response.data.data.login_logo
            ? {
              name: response.data.data.login_logo,
              url: response.data.data.login_logo,
            }
            : null,
          header_logo: response.data.data.header_logo
            ? {
              name: response.data.data.header_logo,
              url: response.data.data.header_logo,
            }
            : null,
        });
        setLogoModeRadio(response.data.data.logo_mode);
        localStorage.setItem("title", response?.data?.data.title);
        localStorage.setItem("favIcon", response?.data?.data.fav_icon);
        localStorage.setItem("headerLogo", response?.data?.data.header_logo);
        localStorage.setItem("loginLogo", response?.data?.data.login_logo);
        localStorage.setItem("logoMode", response?.data?.data.logo_mode);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error, "getGeneral setting error");
    }
  };
  useEffect(() => generalSettingApp(), []);
  const favIcon = localStorage.getItem("favIcon");
  // useEffect((url) =>{
  //     console.log(`${Service.Server_Base_URL}/uploads/${favIcon}`,'logo');
  //   let link = document.queryCommandValue("link[rel~={icon}]");
  //   if(!link){
  //     link = document.createElement('link');
  //     link.rel = 'icon';
  //     document.getElementsByTagName('head')[0].appendChild(`${Service.Server_Base_URL}/uploads/${favIcon}`);
  //   }
  //   link.href = url;

  // },[]);

  const uploadFiles = async (files, type) => {
    try {
      const formData = new FormData();
      for (const file of files) {
        console.log(file, "responseresponse");
        formData.append("document", file);
      }
      console.log(formData.entries(), "responseresponse");
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: `${Service.files_upload_file_for}?file_for=${type}`,
        body: formData,
        options: {
          "content-type": "multipart/form-data",
        },
      });

      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };
  const handlegeneralSetting = async (values) => {
    const fileAttachment = Object.keys(fileLists)
      .map((key) => ({ key, file: fileLists[key] }))
      .filter(({ file }) => file instanceof File);
    const uploadedPaths = {};

    if (fileAttachment.length > 0) {
      const filesToUpload = fileAttachment.map(({ file }) => file);
      const uploadedFiles = await uploadFiles(filesToUpload, "appSetting");
      console.log(uploadedFiles, "responseresponse", filesToUpload);
      if (uploadedFiles.length > 0) {
        fileAttachment.forEach(({ key }, index) => {
          uploadedPaths[key] = uploadedFiles[index].file_path;
        });
      } else {
        return message.error("File not uploaded, something went wrong");
      }
    }

    try {
      dispatch(showAuthLoader());
      const reqBody = {
        title: values.title,
        fav_icon: uploadedPaths.fav_logo || fileLists.fav_logo.name,
        logo_mode: values.logo_mode || logoModeRadio || "dark",
        login_logo: uploadedPaths.login_logo || fileLists.fav_logo.name,
        header_logo: uploadedPaths.header_logo || fileLists.fav_logo.name,
      };

      const response = await Service.makeAPICall({
        api_url: Service.addGeneralSetting,
        methodName: Service.postMethod,
        body: reqBody,
        options: {
          "content-type": "multipart/form-data",
        },
      });

      if (response?.data && response?.data?.data) {
        dispatch(hideAuthLoader());
        setGeneralAdd(response?.data?.data);
        await generalSettingApp();

        console.log(generalAdd, "add edit api response");
      }
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error, "add general setting error");
    }
  };

  const handleFileChange = async (e, itemName) => {
    const file = e.target.files[0];
    const allowedExtensions = ["png", "jpg", "jpeg", "svg", "ico"];
    const extension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(extension)) {
      message.error(
        `File "${file.name}" is not a valid image format. Please select PNG, JPG, JPEG, or SVG file.`
      );
      return;
    }

    const dimensions = await getImageDimensions(file);
    const constraints = dimensionConstraints[itemName];
    if (!constraints) {
      message.error(`No dimension constraints found for item "${itemName}".`);
      return;
    }

    if (
      dimensions.width !== constraints.width ||
      dimensions.height !== constraints.height
    ) {
      message.error(`Image "${itemName}" must be exactly ${constraints.width}x${constraints.height} pixels.
     The uploaded image is ${dimensions.width}x${dimensions.height} pixels.
     `);
      return;
    }

    setFileLists((prevLists) => ({
      ...prevLists,
      [itemName]: file,
    }));

    setTempUrl((prevUrls) => ({
      ...prevUrls,
      [itemName]: URL.createObjectURL(file),
    }));
  };
  const handleRemoveFile = (itemName) => {
    setFileLists((prevLists) => ({
      ...prevLists,
      [itemName]: null,
    }));
    setTempUrl((prevUrls) => ({
      ...prevUrls,
      [itemName]: null,
    }));
    if (attachmentfileRefs[itemName].current) {
      attachmentfileRefs[itemName].current.value = null;
    }
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
    // readNotificationFromUser();
  };

  useEffect(() => {
    // getNotification();
    setInterval(() => {
      // getNotification();
    }, 300000);
  }, []);

  let ids = [
    data?.map((item) => {
      return item?._id;
    }),
  ];

  const readNotification = async (notification_id, slug) => {
    try {
      if (props.location.pathname == slug) {
        window.location.href = slug;
      }
      setVisible(false);
      const reqBody = {
        notification_id: notification_id,
        user_id: authUser?._id,
      };
      //   dispatch(showAuthLoader());

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.readNotification,
        body: reqBody,
      });
      if (response.data.status === 200) {
        // setdata(response?.data?.data);
        // setCountdata(response?.data.metaData.totalCount);
        // message.success(response.metaData.totalCount);
        // console.log(response.data.metaData.totalCount )
      }
      //   dispatch(hideAuthLoader());
    } catch (error) {
      // dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  const changepassword = async (value) => {
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        oldpassword: value?.current_password,
        newPassword: value?.confirm_password,
        emp_id: authUser?._id,
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.hrmschangepassword,
        body: reqBody,
      });
      if (response.data.status === 200) {
        message.success(response.data.message);
        setIsModalOpen(false);
      } else {
        message.error(response.data.message);
      }
      dispatch(hideAuthLoader());
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const [openPopover, setOpenPopover] = useState(false);

  const { authUser } = useSelector(({ auth }) => auth);
  const first_name =
    authUser.first_name.charAt(0).toUpperCase() + authUser.first_name.slice(1);
  const last_name =
    authUser.last_name.charAt(0).toUpperCase() + authUser.last_name.slice(1);

  const userName = first_name + " " + last_name;

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {authUser?.emp_code && (
        <>
          {" "}
          <li
            onClick={() => {
              setOpenPopover(false);
              props.history.push("/my-profile");
            }}
          >
            Edit Profile
          </li>
          <li
            onClick={() => {
              props.history.push("/employee-overview");
            }}
          >
            Employee Overview
          </li>
          <li onClick={showModal}>Change Password</li>
          {/* // */}
          {getRoles(["Admin"]) && <li onClick={showModal1}>Settings</li>}
          {/* // */}
          <li onClick={() => dispatch(userSignOut())}>Logout</li>
        </>
      )}
      {!authUser?.emp_code && (
        <>
          {" "}
          <li onClick={() => props.history.push("/admin-edit")}>
            Edit Profile
          </li>
          <li onClick={() => dispatch(userSignOut())}>Logout</li>
        </>
      )}
    </ul>
  );

  const validator = (rule, value) => {
    if (rule.field === "email") {
      if (
        value &&
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      ) {
        return Promise.reject("Please enter valid Email!");
      } else {
        return Promise.resolve();
      }
    }
  };

  const getadminAccountSetting = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        api_url: Service.adminAccountSetting,
        methodName: Service.getMethod,
      });
      if (response?.data && response?.data?.data) {
        console.log(response, "response");

        console.log(
          moment(response.data.data.companyTime.startAt),
          "mmoonntt",
          response.data.data.companyTime.startAt
        );
        if (response.data.data == undefined || response.data.dataq == {}) {
          message.error('Please complite Account Setting')
        }
        setDataGt({
          SMTP: {
            email: response.data.data.SMTP.email,
            password: response.data.data.SMTP.password,
          },
          working_hours: {
            full_day: response.data.data.working_hours.full_day,
            half_day: response.data.data.working_hours.half_day,
            break_time: response.data.data.working_hours.break_time,
            total_time: response.data.data.working_hours.total_time,
          },
          companyTime: {
            startAt: response.data.data.companyTime.startAt,
            closeAt: response.data.data.companyTime.closeAt,
          },
          group_email: response.data.data.group_email,
          probation_duration: response.data.data.probation_duration,
          isLeaveAutoApprove: response.data.data.isLeaveAutoApprove,
          isWHFAutoApprove: response.data.data.isWHFAutoApprove,
          isRegularizationAutoApprove: response.data.data.isRegularizationAutoApprove,
          birthdayLeave: {
          isApplicable:  response.data.data.birthdayLeave.isApplicable,
          numOfDay:  response.data.data.birthdayLeave.numOfDay
        }
        });
        settggelLeave(response.data.data.birthdayLeave.isApplicable)
        dispatch(hideAuthLoader({}));

      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(dataGt, 'value');


  const adminAccountSettingPost = async (values) => {
    // tggelLeave?'':''
    const data =
    tggelLeave?
     {
      SMTP: { email: values.email, password: values.password },
      working_hours: {
        full_day: moment(values.full_day).format("HH:mm") ? moment(values.full_day).format("HH:mm") : values.full_day,
        half_day: moment(values.half_day).format("HH:mm"),
        break_time: moment(values.break_time).format("HH:mm"),
        total_time: moment(values.total_time).format("HH:mm"),
      },
      group_email: values.group_email,
      probation_duration: values.probation_duration,
      isLeaveAutoApprove: values.isLeaveAutoApprove,
      isWHFAutoApprove: values.isWHFAutoApprove,
      isRegularizationAutoApprove: values.isRegularizationAutoApprove,

      companyTime: {
        startAt: moment(values.startAt).format("HH:mm"),
        closeAt: moment(values.closeAt).format("HH:mm"),
      },
      
        birthdayLeave: {
        isApplicable: values.isApplicable,
        numOfDay: values.numOfDay
    }
    }:{
      SMTP: { email: values.email, password: values.password },
      working_hours: {
        full_day: moment(values.full_day).format("HH:mm") ? moment(values.full_day).format("HH:mm") : values.full_day,
        half_day: moment(values.half_day).format("HH:mm"),
        break_time: moment(values.break_time).format("HH:mm"),
        total_time: moment(values.total_time).format("HH:mm"),
      },
      group_email: values.group_email,
      probation_duration: values.probation_duration,
      isLeaveAutoApprove: values.isLeaveAutoApprove,
      isWHFAutoApprove: values.isWHFAutoApprove,
      isRegularizationAutoApprove: values.isRegularizationAutoApprove,

      companyTime: {
        startAt: moment(values.startAt).format("HH:mm"),
        closeAt: moment(values.closeAt).format("HH:mm"),
      },
      
        birthdayLeave: {
        isApplicable: false,
        numOfDay: 0
    }}
    console.log(values, 'values', dataGt,data,tggelLeave);


    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        api_url: Service.adminAccountSettingAddEdit,
        methodName: Service.postMethod,
        body: data,
      });
      if (response?.data && response?.data?.data) {
        message.success(response.data.message);
        getadminAccountSetting();

        dispatch(hideAuthLoader());
      }
    } catch (error) {
      dispatch(hideAuthLoader());
      console.log(error, "getMethod error");
    }
  };

  const onPermissionChange = (name) => {
    // console.log(checked,'checkedchecked');
    setDataGt({ ...dataGt, isLeaveAutoApprove: !dataGt.isLeaveAutoApprove })
  }
  const onPermissionChangeWFH = (name) => {
    // console.log(checked,'checkedchecked');
    setDataGt({ ...dataGt, isWHFAutoApprove: !dataGt.isWHFAutoApprove })
  }
  const onPermissionChangeREG = (name) => {
    // console.log(checked,'checkedchecked');
    setDataGt({ ...dataGt, isRegularizationAutoApprove: !dataGt.isRegularizationAutoApprove })
  }
  useEffect(() => {
    getadminAccountSetting();
  }, []);

  useEffect(() => {
    localStorage.setItem('account_setting', JSON.stringify(dataGt))
  }, [dataGt])

  const calculateTotalTime = (fullDay, breakTime) => {
    if (!fullDay || !breakTime) return null;

    // Calculate the difference in milliseconds
    const fullDayDuration = moment.duration(fullDay).asMilliseconds();
    const breakTimeDuration = moment.duration(breakTime).asMilliseconds();

    // Add the durations
    const totalDuration = fullDayDuration + breakTimeDuration;

    // Convert back to moment object
    return moment.utc(totalDuration);
  };

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      { }
      {notificationAccessBlock.includes(authUser?.role_id) ? (
        <></>
      ) : (
        <Popover
          placement="bottomRight"
          visible={visible}
          onVisibleChange={handleVisibleChange}
          content={
            <div className="notidication-modal">
              <div className="refresh-icon-box">
                <span
                  className={
                    flag == true ? "refresh-icon rotation" : "refresh-icon"
                  }
                  id="refresh-icon"
                  onClick={() => {
                    setflag(true);
                    // getNotification();
                  }}
                >
                  <BiRefresh />
                </span>
              </div>
              <ul className="notify-ul">
                {data.length > 0 ? (
                  data.map((item) => (
                    <li>
                      <div className="label">
                        <div className="label-date">
                          {item?.notification_type == "0" ? (
                            <label style={{ background: "#ff5252" }}>
                              {item?.leave_type} Applied
                            </label>
                          ) : item?.notification_type == "1" ? (
                            <label style={{ background: "#4b40ff" }}>
                              Attendance Regularization Applied
                            </label>
                          ) : item?.notification_type == "2" ? (
                            <label style={{ background: "#72008f" }}>
                              WFH Request Applied
                            </label>
                          ) : // item?.notification_type == "3" ? (
                            //   <label style={{ background: "#0e7e0e" }}>
                            //     {item.award_type} of The Month
                            //   </label>
                            // ) :
                            item?.notification_type == "4" ? (
                              <label style={{ background: "#187cb7" }}>
                                New Polices Added
                              </label>
                            ) : item?.notification_type == "5" ? (
                              <label style={{ background: "#b36624" }}>
                                New Ticket Added
                              </label>
                            ) : item?.notification_type == "6" ? (
                              <label style={{ background: "#cf5f00" }}>
                                Comp-off Applied
                              </label>
                            ) : item?.notification_type == "7" ? (
                              <label style={{ background: "#4CAF50" }}>
                                New Comment Added
                              </label>
                            ) : //  item?.notification_type == "8" ? (
                              //   <label style={{ background: "#cf008f" }}>
                              //     Happy Birthday
                              //   </label>
                              // )                         :
                              // item?.notification_type == "9" ? (
                              //   <label style={{ background: "#5c1c49" }}>
                              //     Happy Work Anniversary
                              //   </label>
                              // ) :
                              item?.notification_type == "10" ? (
                                <label style={{ background: "#e71269" }}>
                                  Reminder{" "}
                                </label>
                              ) : item?.notification_type == "12" ? (
                                <label style={{ background: "#bd6eeb" }}>
                                  New Job opening created
                                </label>
                              ) : item?.notification_type == "14" ? (
                                <label style={{ background: "#4CAF50" }}>
                                  Job status changed
                                </label>
                              ) : item?.notification_type == "13" ? (
                                <label style={{ background: "#4CAF50" }}>
                                  New Comment Added For A Job
                                </label>
                              ) : item?.notification_type == "15" ? (
                                <label style={{ background: "#4b40ff" }}>
                                  On Duty Regularization Applied
                                </label>
                              ) : (
                                ""
                              )}
                          <div className="date-time">
                            <span>
                              {moment(item?.created_At).format("DD/MM/YYYY")}
                            </span>
                            |
                            <span>
                              {moment(item?.created_At).format("hh:mm a")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="app-notify">
                        {item?.notification_type == "12" ||
                          item?.notification_type == "14" ? (
                          <img
                            src={JobIcon}
                            className="job-icon"
                            alt="job-icon"
                            width="35"
                            height="35"
                          />
                        ) : item?.notification_type == "4" ? (
                          <img
                            src={PdfIcon}
                            className="pdf-icon"
                            alt="pdf-icon"
                            width="35"
                            height="45"
                          />
                        ) : (
                          <img
                            src={
                              item.emp_img !== ""
                                ? `${Service.Server_Base_URL}/uploads/emp_images/${item.emp_img}`
                                : ProfileImage
                            }
                            className="avatar-user"
                            alt="user-icon"
                          />
                        )}
                        <div
                          className={
                            item?.notification_type == "4"
                              ? "policy-notify-data notify-data"
                              : "notify-data"
                          }
                        >
                          <div className="notify-name">
                            {item?.notification_type == "4" && (
                              <h5>Policy name : {item?.policy_name}</h5>
                            )}

                            {item?.notification_type == "0" && (
                              <>
                                <p>
                                  <b>Name :</b> {item?.employee_name}
                                </p>
                                <p>
                                  <b>From Date :</b>{" "}
                                  {moment(item?.from_date).format("DD/MM/YYYY")}
                                </p>
                                <p>
                                  <b>To Date :</b>{" "}
                                  {moment(item?.to_date).format("DD/MM/YYYY")}
                                </p>
                                <p>
                                  <b>Leave Type:</b> {item.leave_type}
                                </p>
                              </>
                            )}
                            {item.notification_type == "1" && (
                              <>
                                <p>
                                  <b>Name :</b> {item?.employee_name}
                                </p>
                                <p>
                                  <b>Date :</b>{" "}
                                  {moment(item?.regulizarions_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                <p>
                                  <b>Type :</b> Attendance Regularization
                                </p>
                              </>
                            )}
                            {item.notification_type == "2" && (
                              <>
                                <p>
                                  <b>Name :</b> {item?.employee_name}
                                </p>
                                <p>
                                  Date :{" "}
                                  {moment(item?.wfh_from_date).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                </p>
                                <p>
                                  <b>Type :</b> WFH Request
                                </p>
                              </>
                            )}
                            {/* {item.notification_type == "3" && (
                            <>
                              <p>Achiever : {item?.employee_name}</p>
                              <p>Profile: {item?.designation_name}</p>
                            </>
                          )} */}

                            {item.notification_type == "5" && (
                              <>
                                <p>
                                  <b>Subject:</b> {item.ticket_name}{" "}
                                </p>
                                <p>
                                  <b>Ticket Raiser:</b> {item.employee_name}
                                </p>
                              </>
                            )}
                            {item.notification_type == "6" && (
                              <>
                                <p>
                                  <b>Name :</b> {item.employee_name}{" "}
                                </p>
                                <p>
                                  <b>Extra Work Date :</b>{" "}
                                  {moment(item.compoff_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                              </>
                            )}
                            {item.notification_type == "7" && (
                              <>
                                <p>
                                  <b>Name :</b> {item.employee_name}{" "}
                                </p>
                                <p>
                                  <b>Ticket Name:</b> {item.ticket_name}
                                </p>
                              </>
                            )}
                            {item.notification_type == "12" && (
                              <>
                                <p>
                                  <b>Job Title :</b> {item.jobs.title}{" "}
                                </p>
                                <p>
                                  <b>Openings:</b> {item.jobs.no_of_opening}
                                </p>
                                <p>
                                  <b>Priority:</b> {item.jobs.priority}
                                </p>
                              </>
                            )}
                            {item.notification_type == "14" && (
                              <>
                                <p>
                                  <b>Job Title :</b> {item.jobs.title}{" "}
                                </p>
                                <p>
                                  <b>Openings:</b> {item.jobs.no_of_opening}
                                </p>
                                <p>
                                  <b>Priority:</b> {item.jobs.priority}
                                </p>
                              </>
                            )}
                            {item.notification_type == "13" && (
                              <>
                                <p>
                                  <b>Name: </b>
                                  {item.employee_name}
                                </p>
                                <p>
                                  <b>Job Title:</b> {item.jobs?.title}
                                </p>
                              </>
                            )}
                            {/* {item.notification_type == "9" && (
                            <>
                              {authUser?._id == item?.sender_id ? (
                                <p>
                                  Elsner Technologies Wishing You a{" "}
                                  {item.work_complete} year Work Anniversary
                                </p>
                              ) : (
                                <p>
                                  Make a wish for {item.employee_name} To
                                  complete {item.work_complete} year In Elsner.
                                </p>
                              )}
                            </>
                          )} */}
                            {/* {item.notification_type == "8" && (
                            <>
                              {authUser?._id == item?.sender_id ? (
                                <p>
                                  Elsner Technologies Wishing You a Very Happy
                                  birthday.
                                </p>
                              ) : (
                                <p>
                                  Make a birthday wish for {item.employee_name}
                                </p>
                              )}
                            </>
                          )} */}
                            {item.notification_type == "10" && (
                              <>
                                <p>
                                  Reminder! {item?.employee_name} Probation
                                  period is about to end in 7 days.
                                </p>
                              </>
                            )}
                            {item.notification_type == "15" && (
                              <>
                                <p>
                                  <b>Name :</b> {item?.employee_name}
                                </p>
                                <p>
                                  <b>From Date :</b>{" "}
                                  {moment(item?.onduty_from_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                <p>
                                  <b>To Date :</b>{" "}
                                  {moment(item?.onduty_to_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                <p>
                                  <b>Type :</b>{" "}
                                  {item?.onduty_regularization_type}
                                </p>
                              </>
                            )}
                            {/* <>
                            <p>
                              <b>Profile:</b> Jr. JS Developer
                            </p>
                            <p>
                              Probation ending: 23-Apr
                            </p>
                          </> */}
                          </div>
                          {/* {item.notification_type == "3" && (
                          <img
                            src={CelebrationIcon}
                            className="represent-icon celebration-icon"
                            alt="celebration-icon"
                            width="45"
                            height="45"
                          />
                        )} */}
                          {item.notification_type == "5" && (
                            <div className="notify-status">
                              <a href={item?.slug}>
                                <Button
                                  className="btn-success"
                                  onClick={() => setVisible(false)}
                                >
                                  View Ticket
                                </Button>
                              </a>
                            </div>
                          )}
                          {item.notification_type == "7" && (
                            <div className="notify-status">
                              <a href={item?.slug}>
                                <Button
                                  className="btn-success"
                                  onClick={() => setVisible(false)}
                                >
                                  View Ticket Comment
                                </Button>
                              </a>
                            </div>
                          )}
                          {item.notification_type == "4" && (
                            <div className="notify-status">
                              <Link to={item?.slug}>
                                <Button
                                  className="btn-success"
                                  onClick={() => {
                                    readNotification(item._id, item.slug);
                                    setVisible(false);
                                  }}
                                >
                                  View Policy
                                </Button>
                              </Link>
                            </div>
                          )}
                          {(item.notification_type == "12" ||
                            item.notification_type == "14" ||
                            item.notification_type == "13") && (
                              <div className="notify-status">
                                <Link to={item?.slug}>
                                  <Button
                                    className="btn-success"
                                    onClick={() => {
                                      readNotification(item._id, item.slug);
                                      setVisible(false);
                                    }}
                                  >
                                    View Job
                                  </Button>
                                </Link>
                              </div>
                            )}

                          {item?.notification_type == "6" && (
                            <div className="notify-status">
                              <>
                                {item?.compoff_status == "Rejected" ? (
                                  <label className="status-error">
                                    Rejected
                                  </label>
                                ) : item?.notification_status == "Accepted" ? (
                                  <label className="status-success">
                                    Approved
                                  </label>
                                ) : item?.tl_compoff_status == "Accepted" &&
                                  item?.director_compoff_status == "Pending" &&
                                  authUser?._id !=
                                  "63b6ca8770f2b5b3002cb43e" ? (
                                  <label className="status-success">
                                    Approved
                                  </label>
                                ) : item?.tl_compoff_status == "Rejected" &&
                                  item?.director_compoff_status == "Pending" &&
                                  authUser?._id !=
                                  "63b6ca8770f2b5b3002cb43e" ? (
                                  <label className="status-error">
                                    Rejected
                                  </label>
                                ) : item?.director_compoff_status ==
                                  "Accepted" &&
                                  item?.ceo_compoff_status == "Pending" &&
                                  authUser?._id !=
                                  "63b6ca8670f2b5b3002cb433" ? (
                                  <label className="status-success">
                                    Approved
                                  </label>
                                ) : item?.director_compoff_status ==
                                  "Rejected" &&
                                  item?.ceo_compoff_status == "Pending" &&
                                  authUser?._id !=
                                  "63b6ca8670f2b5b3002cb433" ? (
                                  <label className="status-error">
                                    Rejected
                                  </label>
                                ) : item?.ceo_compoff_status == "Accepted" &&
                                  authUser?._id ==
                                  "63b6ca8670f2b5b3002cb433" ? (
                                  <label className="status-success">
                                    Approved
                                  </label>
                                ) : item?.ceo_compoff_status == "Rejected" &&
                                  authUser?._id ==
                                  "63b6ca8670f2b5b3002cb433" ? (
                                  <label className="status-error">
                                    Rejected
                                  </label>
                                ) : (
                                  <Link to={item.slug}>
                                    <Button
                                      className="btn-success"
                                      onClick={(event) => {
                                        readNotification(item._id, item.slug);
                                        setVisible(false);
                                      }}
                                    >
                                      Click to Approve/Reject
                                    </Button>
                                  </Link>
                                )}
                              </>
                            </div>
                          )}

                          {item.status == "Pending" ? (
                            authUser?.emp_code == item.employee_code ? (
                              item.status && (
                                <div className="notify-status">
                                  {item.status == "Pending" ? (
                                    <label className="status-error">
                                      {item.status}
                                    </label>
                                  ) : (
                                    <label className="status-success">
                                      {item.status}
                                    </label>
                                  )}
                                </div>
                              )
                            ) : (
                              <div className="notify-status">
                                {item?.notification_type == "0" && (
                                  <>
                                    {item?.leave_status == "Accepted" ? (
                                      <div className="notify-status">
                                        <label className="status-success">
                                          Approved
                                        </label>
                                      </div>
                                    ) : item?.leave_status == "Rejected" ? (
                                      <div className="notify-status">
                                        <label className="status-error">
                                          Rejected
                                        </label>
                                      </div>
                                    ) : (
                                      <>
                                        <Link to={item.slug}>
                                          <Button
                                            className="btn-success"
                                            onClick={(event) => {
                                              readNotification(
                                                item._id,
                                                item.slug
                                              );
                                              setVisible(false);
                                            }}
                                          >
                                            Click to Approve/Reject
                                          </Button>
                                        </Link>
                                      </>
                                    )}
                                  </>
                                )}

                                {item?.notification_type == "1" && (
                                  <>
                                    {item?.tl_regularized_status ==
                                      "approved" &&
                                      (item?.director_regularized_status ==
                                        "pending" ||
                                        item?.director_regularized_status ==
                                        "approved") &&
                                      authUser?._id !=
                                      "63b6ca8770f2b5b3002cb43e" &&
                                      authUser?._id !=
                                      "63b6ca9270f2b5b3002cb552" &&
                                      authUser?._id !=
                                      "63b6ca8670f2b5b3002cb433" ? (
                                      <label className="status-success">
                                        Approved
                                        {/* TL */}
                                      </label>
                                    ) : item?.director_regularized_status ==
                                      "approved" &&
                                      (item?.ceo_regularized_status ==
                                        "pending" ||
                                        item?.ceo_regularized_status ==
                                        "approved") &&
                                      authUser?._id !=
                                      "63b6ca8670f2b5b3002cb433" &&
                                      authUser?._id !=
                                      "63b6ca9270f2b5b3002cb552" ? (
                                      <label className="status-success">
                                        Approved
                                        {/* Director */}
                                      </label>
                                    ) : item?.ceo_regularized_status ==
                                      "approved" &&
                                      authUser?._id ==
                                      "63b6ca8670f2b5b3002cb433" ? (
                                      <label className="status-success">
                                        Approved
                                        {/* CEO */}
                                      </label>
                                    ) : item?.ceo_regularized_status ==
                                      "approved" &&
                                      item?.admin_regularized_status ==
                                      "approved" &&
                                      authUser?._id ==
                                      "63b6ca9270f2b5b3002cb552" ? (
                                      <label className="status-success">
                                        Approved
                                        {/* ADMIN */}
                                      </label>
                                    ) : (
                                      <Link to={item.slug}>
                                        <Button
                                          className="btn-success"
                                          onClick={(event) => {
                                            readNotification(
                                              item._id,
                                              item.slug
                                            );
                                            setVisible(false);
                                          }}
                                        >
                                          Click to Approve/Reject
                                        </Button>
                                      </Link>
                                    )}
                                    {/* {item?.status == "Accepted" ? (
                                    <div className="notify-status">
                                      <label className="status-success">
                                        Approved
                                      </label>
                                    </div>
                                  ) : item?.status == "Rejected" ? (
                                    <div className="notify-status">
                                      <label className="status-error">
                                        Rejected
                                      </label>
                                    </div>
                                  ) : authUser?.is_reporting_manager == true &&
                                    item?.tl_regularized_status ==
                                      "approved" ? (
                                    <label className="status-success">
                                      Approved
                                    </label>
                                  ) : (
                                    <Link to={item.slug}>
                                      <Button
                                        className="btn-success"
                                        onClick={(event) => {
                                          readNotification(item._id, item.slug);
                                          setVisible(false);
                                        }}
                                      >
                                        Click to Approve/Reject
                                      </Button>
                                    </Link>
                                  )} */}

                                    {/* <Link to={item.slug}>
                                    <Button
                                      className="btn-success"
                                      onClick={(event) => {
                                        readNotification(item._id, item.slug);
                                        setVisible(false);
                                      }}
                                    >
                                      Click to Approve/Reject
                                    </Button>
                                  </Link> */}
                                  </>
                                )}

                                {item?.notification_type == "2" && (
                                  <Link to={item.slug}>
                                    <Button
                                      className="btn-success"
                                      onClick={(event) => {
                                        readNotification(item._id, item.slug);
                                        setVisible(false);
                                      }}
                                    >
                                      Click to Approve/Reject
                                    </Button>
                                  </Link>
                                )}

                                {item?.notification_type == "15" && (
                                  <>
                                    {item?.onduty_status == "Accepted" ? (
                                      <div className="notify-status">
                                        <label className="status-success">
                                          Approved
                                        </label>
                                      </div>
                                    ) : item?.onduty_status == "Rejected" ? (
                                      <div className="notify-status">
                                        <label className="status-error">
                                          Rejected
                                        </label>
                                      </div>
                                    ) : (
                                      <>
                                        <Link to={item.slug}>
                                          <Button
                                            className="btn-success"
                                            onClick={(event) => {
                                              readNotification(
                                                item._id,
                                                item.slug
                                              );
                                              setVisible(false);
                                            }}
                                          >
                                            Click to Approve/Reject
                                          </Button>
                                        </Link>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            )
                          ) : item.status == "Approved" ? (
                            <div className="notify-status">
                              <label className="status-success">Approved</label>
                            </div>
                          ) : (
                            item.status && (
                              <div className="notify-status">
                                <label className="status-error">
                                  {item.status}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <p className="not-found">No New notifications</p>
                )}
              </ul>
            </div>
          }
          trigger="click"
        >
          <div className="bell-icon">
            <CiBellOn className="notification-icon" />
            {dataCount != "" ? (
              <span className="count">{dataCount}</span>
            ) : (
              <span className=" no_count"></span>
            )}
          </div>
        </Popover>
      )}

      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        onVisibleChange={(newVisible) => setOpenPopover(newVisible)}
      >
        <img
          src={
            authUser.emp_img
              ? `${Service.Server_Base_URL}/uploads/emp_images/${authUser.emp_img}`
              : ProfileImage
          }
          className="avatar-user"
          alt="User"
        />
        <span className="gx-avatar-name">
          {userName}
          <i className="icon icon-chevron-down gx-ml-2" />
        </span>
      </Popover>

      <Modal
        footer={false}
        title="Change Password"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={(values) => {
            changepassword(values);
          }}
          {...formItemLayout}
        >
          <Form.Item
            label="Current Password"
            name="current_password"
            className="search-row"
            rules={[
              {
                required: true,
                message: "Please enter Current Password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            className="search-row"
            rules={[
              {
                required: true,
                message: "Please enter New Password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            className="search-row"
            rules={[
              {
                required: true,
                message: "Please enter Confirm Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The confirm passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div className="profilecenter-uploadbtn">
            <Button key="back" type="primary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Change Password
            </Button>
          </div>
        </Form>
      </Modal>

      {getRoles(["Admin"]) && (
        <>
          <Modal
            footer={false}
            title="Settings"
            visible={isModalOpen1}
            onOk={handleOk1}
            onCancel={handleCancel1}
          // style={{width:'880px'}}
          >
            <Tabs style={{ marginTop: "-2rem" }}>
              <TabPane key="1" tab="General Setting">
                <Form form={generalSetting} onFinish={handlegeneralSetting}>
                  <div className="overview-modal-wrapper">
                    <div className="logosettings-wrapper">
                      <h4>Login logo : &nbsp;&nbsp;</h4>
                      <span>(This logo will appear on loginpage)</span>
                      <Form.Item name="login_logo">
                        <div
                          className="upload-btn-img-wrapper"
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          {(fileLists.login_logo || tempurl.login_logo) && (
                            <div className="logo-view-wrapper">
                              {/* {user_data?.settings
                        ?.login_logo                      } */}
                              <img
                                src={
                                  tempurl.login_logo
                                    ? tempurl.login_logo
                                    : `${Service.Server_Base_URL}/uploads/${fileLists.login_logo.url}`
                                }
                                alt={
                                  fileLists?.login_logo
                                    ? fileLists.login_logo.name
                                    : ""
                                }
                                style={{ height: "50px", width: "150px" }}
                              />
                            </div>
                          )}

                          <Button
                            className="link-btn"
                            onClick={() =>
                              attachmentfileRefs.login_logo.current.click()
                            }
                            disabled={
                              fileLists.login_logo || tempurl.login_logo
                                ? true
                                : false
                            }
                          >
                            <UploadOutlined /> Upload Login Logo
                          </Button>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, "login_logo")}
                            hidden
                            ref={attachmentfileRefs.login_logo}
                          />
                          {(fileLists.login_logo || tempurl.login_logo) && (
                            <Button
                              icon={<CloseOutlined />}
                              onClick={() => handleRemoveFile("login_logo")}
                              size="small"
                            />
                          )}
                        </div>
                      </Form.Item>
                    </div>

                    {/* Header Logo */}
                    <div className="logosettings-wrapper">
                      <h4>Header logo : &nbsp;&nbsp;</h4>
                      <span>(This logo will appear on Header)</span>
                      <Form.Item name="header_logo">
                        <div
                          className="upload-btn-img-wrapper"
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          {(fileLists?.header_logo || tempurl?.header_logo) && (
                            <div className="logo-view-wrapper">
                              <img
                                src={
                                  tempurl.header_logo
                                    ? tempurl.header_logo
                                    : `${Service.Server_Base_URL}/uploads/${fileLists.header_logo.url}`
                                }
                                alt={
                                  fileLists?.header_logo
                                    ? fileLists?.header_logo?.name
                                    : ""
                                }
                                style={{ height: "50px", width: "150px" }}
                              />
                            </div>
                          )}

                          <Button
                            className="link-btn"
                            onClick={() =>
                              attachmentfileRefs.header_logo.current.click()
                            }
                            disabled={
                              fileLists?.header_logo || tempurl?.header_logo
                                ? true
                                : false
                            }
                          >
                            <UploadOutlined /> Upload Header Logo
                          </Button>

                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, "header_logo")}
                            hidden
                            ref={attachmentfileRefs.header_logo}
                          />

                          {(fileLists.header_logo || tempurl.header_logo) && (
                            <Button
                              icon={<CloseOutlined />}
                              onClick={() => handleRemoveFile("header_logo")}
                              size="small"
                            />
                          )}
                        </div>
                      </Form.Item>
                    </div>

                    {/* FavIcon */}
                    <div className="logosettings-wrapper">
                      <h4>FavIcon logo : &nbsp;&nbsp;</h4>
                      <span>(This logo will appear on Title)</span>
                      <Form.Item name="fav_logo">
                        <div
                          className="upload-btn-img-wrapper"
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          {(fileLists?.fav_logo || tempurl?.fav_logo) && (
                            <div className="favicon-view-wrapper">
                              <img
                                src={
                                  tempurl.fav_logo
                                    ? tempurl.fav_logo
                                    : `${Service.Server_Base_URL}/uploads/${fileLists.fav_logo.url}`
                                }
                                alt={
                                  fileLists?.fav_logo
                                    ? fileLists?.fav_logo?.name
                                    : ""
                                }
                              />
                            </div>
                          )}

                          <Button
                            className="link-btn"
                            onClick={() =>
                              attachmentfileRefs.fav_logo.current.click()
                            }
                            disabled={
                              fileLists?.fav_logo || tempurl?.fav_logo
                                ? true
                                : false
                            }
                          >
                            <UploadOutlined /> Upload FavIcon Logo
                          </Button>

                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, "fav_logo")}
                            hidden
                            ref={attachmentfileRefs.fav_logo}
                          />

                          {(fileLists.fav_logo || tempurl.fav_logo) && (
                            <Button
                              icon={<CloseOutlined />}
                              onClick={() => handleRemoveFile("fav_logo")}
                              size="small"
                            />
                          )}
                        </div>
                      </Form.Item>
                    </div>

                    {/* <Form.Item
                            label="Logo Mode"
                            name="logo_mode"
                            initialValue={logoModeRadio}
                          >
                            <div>
                              <Radio.Group onChange={(e) => { setLogoModeRadio(e.target.value) }} value={logoModeRadio}>
                                <Radio value="dark">Dark</Radio>
                                <Radio value="light">Light</Radio>
                              </Radio.Group>
                            </div>
                          </Form.Item> */}

                    <div className="logosettings-wrapper">
                      <h4>Title</h4>
                      <Form.Item
                        wrapperCol={{ span: 24 }}
                        name="title"
                        initialValue={generalApp.title}
                      >
                        <Input
                          name="titleinput"
                          defaultValue={generalApp.title}
                          type="text"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="modal-footer-flex-setting-api">
                    <div
                      className="flex-btn"
                      style={{ display: "flex", gap: "2rem" }}
                    >
                      <Button type="primary" htmlType="submit">
                        Update
                      </Button>
                      <Button
                        onClick={() => {
                          setSettingModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </TabPane>
              <TabPane key="2" tab=" Account Setting ">
                <div
                  style={{ display: "flex", flexDirection: "column-reverse" }}
                  className="ADminSetting"
                >
                  <Form form={AdminSetting} onFinish={adminAccountSettingPost}>
                    <Card title="Company Time ">
                      <div className="Firstdiv">
                        <Form.Item
                          name="startAt"
                          label="Opening Time"
                          required
                          initialValue={moment
                            .utc(dataGt?.companyTime.startAt * 1).add(5, "hours")
                            .add(30, "minute")}

                          rules={[
                            {
                              required: true,
                              message: "Please Enter Opening Time!",
                            },

                          ]}
                        >
                          <TimePicker
                            format={`HH:mm`}
                            name="editin"
                            onChange={(value, dateString) => {
                              AdminSetting.setFieldsValue({
                                startAt: value,
                              });

                            }}
                            defaultValue={
                              dataGt.companyTime.startAt ?
                                moment
                                  .utc(dataGt?.companyTime.startAt)
                                  .format("HH:mm")
                                  ? moment
                                    .utc(dataGt?.companyTime.startAt * 1)
                                    .add(5, "hours")
                                    .add(30, "minute")
                                  : moment.utc() : null
                            }
                          // defaultValue={moment(dataGt?.companyTime?.startAt,format)}
                          />
                        </Form.Item>
                        <Form.Item name="closeAt" label="Closing Time" required
                          initialValue={moment
                            .utc(dataGt.companyTime.closeAt * 1).add(5, "hours")
                            .add(30, "minute")}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Closing Time!",
                            },

                          ]}
                        >
                          <TimePicker
                            format={`HH:mm`}
                            name="editin"
                            // defaultValue={moment(dataGt?.companyTime.startAt)}
                            onChange={(value, dateString) => {
                              AdminSetting.setFieldsValue({
                                closeAt: value,
                              });
                            }}
                            defaultValue={
                              dataGt.companyTime.closeAt ?
                                moment
                                  .utc(dataGt.companyTime.closeAt)
                                  .format("HH:mm")
                                  ? moment
                                    .utc(dataGt.companyTime.closeAt * 1)
                                    .add(5, "hours")
                                    .add(30, "minute")
                                  : moment.utc()
                                : null
                            }
                          />
                          {/* <TimePicker
                          format={`HH:mm`}
                          name="editin"
                          defaultValue={moment(dataGt?.companyTime.closeAt,format)
                          }


                        /> */}
                        </Form.Item>
                      </div>

                    </Card>

                    <Card title="Working Hours ">
                      <div className="Seconddiv">
                        <Form.Item name="full_day" label="Full Day" required
                          initialValue={moment
                            .utc(dataGt.working_hours.full_day * 1).add(5, "hours")
                            .add(30, "minute")}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Full Day Time!",
                            },

                          ]}
                        >
                          <TimePicker
                            format={`HH:mm`}
                            name="editin"
                            // onChange={(value, dateString) => {
                            //   AdminSetting.setFieldsValue({
                            //     full_day: value,
                            //   });
                            // }}
                            onChange={(value) => {
                              const breakTime = AdminSetting.getFieldValue("break_time");
                              const totalTime = calculateTotalTime(value, breakTime);
                              AdminSetting.setFieldsValue({
                                full_day: value,
                                total_time: totalTime,
                              });
                            }}

                            defaultValue={
                              dataGt.working_hours.full_day ?
                                moment
                                  .utc(dataGt.working_hours.full_day)
                                  .format("HH:mm")
                                  ? moment
                                    .utc(dataGt.working_hours.full_day * 1)
                                    .add(5, "hours")
                                    .add(30, "minute")
                                  : moment.utc() :
                                null
                            }
                          />
                          {/* <TimePicker
                          format={`HH:mm`}
                          name="editin"
                          defaultValue={moment(dataGt?.companyTime.full_day)
                          }

                        /> */}
                        </Form.Item>

                        <Form.Item name="half_day" label="Half Day" required
                          initialValue={moment
                            .utc(dataGt.working_hours.half_day * 1).add(5, "hours")
                            .add(30, "minute")}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Half Day Time!",
                            },

                          ]}

                        >
                          <TimePicker
                            format={`HH:mm`}
                            name="editin"
                            onChange={(value, dateString) => {
                              AdminSetting.setFieldsValue({
                                half_day: value,
                              });
                            }}
                            defaultValue={
                              dataGt.working_hours.half_day ?
                                moment
                                  .utc(dataGt.working_hours.half_day)
                                  .format("HH:mm")
                                  ? moment
                                    .utc(dataGt.working_hours.half_day * 1)
                                    .add(5, "hours")
                                    .add(30, "minute")
                                  : moment.utc() : null
                            }
                          // defaultValue={moment(dataGt?.companyTime.startAt)}
                          />
                          {/* <TimePicker
                          format={`HH:mm`}
                          name="editin"
                          defaultValue={moment(dataGt?.companyTime.full_day)
                          }

                        /> */}
                        </Form.Item>
                        <Form.Item name="break_time" label="Break Time" required

                          rules={[
                            {
                              required: true,
                              message: "Please Enter Break Time!",
                            },

                          ]}
                          initialValue={moment
                            .utc(dataGt.working_hours.break_time * 1).add(5, "hours")
                            .add(30, "minute")}
                        >
                          <TimePicker
                            format={`HH:mm`}
                            name="editin"
                            // onChange={(value, dateString) => {
                            //   AdminSetting.setFieldsValue({
                            //     break_time: value,
                            //   });
                            // }}
                            onChange={(value) => {
                              const fullDay = AdminSetting.getFieldValue("full_day");
                              const totalTime = calculateTotalTime(fullDay, value);
                              AdminSetting.setFieldsValue({
                                break_time: value,
                                total_time: totalTime,
                              });
                            }}
                            defaultValue={
                              dataGt.working_hours.break_time ?
                                moment
                                  .utc(dataGt.working_hours.break_time)
                                  .format("HH:mm")
                                  ? moment
                                    .utc(dataGt.working_hours.break_time * 1)
                                    .add(5, "hours")
                                    .add(30, "minute")
                                  : moment.utc() : null
                            }
                          // defaultValue={moment(dataGt?.companyTime.startAt)}
                          />
                          {/* <TimePicker
                          format={`HH:mm`}
                          name="editin"
                          defaultValue={moment(dataGt?.companyTime.break_time)
                          }

                        /> */}
                        </Form.Item>
                        <Form.Item
                          name="total_time"
                          label="Total Time"

                          initialValue={moment
                            .utc(dataGt.working_hours.total_time * 1).add(5, "hours")
                            .add(30, "minute")}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please Enter total time!",
                        //   },
                        // ]}
                        >
                          <TimePicker
                            format={`HH:mm`}
                            name="editin"
                            disabled
                            // onChange={(value, dateString) => {
                            //   AdminSetting.setFieldsValue({
                            //     total_time: value,
                            //   });
                            // }}
                            onChange={(value) => {
                              AdminSetting.setFieldsValue({ total_time: value });
                            }}

                            defaultValue={
                              dataGt.working_hours.total_time ?
                                moment
                                  .utc(dataGt.working_hours.total_time)
                                  .format("HH:mm")
                                  ? moment
                                    .utc(dataGt.working_hours.total_time * 1)
                                    .add(5, "hours")
                                    .add(30, "minute")
                                  : moment.utc() : null
                            }
                          />
                          {/* <TimePicker
                          format={`HH:mm`}
                          name="editin"
                          defaultValue={moment(dataGt?.companyTime.break_time)
                          }


                        /> */}
                        </Form.Item>
                      </div>


                    </Card>
                    <Card title="SMTP ">
                      <div className="ThirdDiv">
                        <Form.Item
                          name="email"
                          label="Email"
                          initialValue={!dataGt ? "" : dataGt?.SMTP.email}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter email!",
                            },
                            { validator: validator },
                          ]}
                        >
                          <Input
                            name="titleEmail"
                            // defaultValue={generalApp.title}
                            type="email"
                          />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          label="Password"
                          initialValue={
                            dataGt?.SMTP.password == undefined
                              ? ""
                              : dataGt?.SMTP.password
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please enter  Password!",
                            },
                          ]}
                        >
                          {/* <Input.Password placeholder="input password" /> */}

                          <Input.Password
                            name="titlepassword"
                            defaultValue={dataGt?.SMTP.password}
                          // iconRender={(visible) => (visible ? <EyeInvisibleOutlined />:   <EyeTwoTone />)}
                          />
                          {/* <EyeOutlined /> */}
                        </Form.Item>

                      </div>

                      {/* <Form.Item
                        name="group_email"
                        label="Commapny Group Email"
                        initialValue={!dataGt ? "" : dataGt?.group_email}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter email!",
                          },
                          { validator: validator },
                        ]}
                      >
                        <Input
                          name="nameemail"
                          // defaultValue={generalApp.title}
                          type="email"
                        />
                      </Form.Item> */}
                    </Card>

                    <Card title='Common Details'>
                      <div className="FourthDiv">
                        <Form.Item
                          label="Group- Email"
                          initialValue={!dataGt ? "" : dataGt?.group_email}
                          rules={[
                            {
                              required: true,
                              message: "Please enter Email!",
                            },
                            {
                              type: "email",
                              message: "Please enter Email",
                            },
                          ]}
                          name="group_email"

                        >
                          <Input type="email" placeholder="Email" />
                        </Form.Item>
                        {/* //start */}
                        <Form.Item
                          label="Probation Duration"
                          name="probation_duration"
                          initialValue={!dataGt ? "---Select---" : dataGt?.probation_duration}
                          rules={[
                            {
                              required: true,
                              message: "Please select!",
                            },
                          ]}
                        >
                          <Select
                            // onChange={handleChange}
                            options={[
                              {
                                value: "1",
                                label: "1",
                              },
                              {
                                value: "2",
                                label: "2",
                              },
                              {
                                value: "3",
                                label: "3",
                              },
                              {
                                value: "4",
                                label: "4",
                              },
                              {
                                value: "5",
                                label: "5",
                              },
                              {
                                value: "6",
                                label: "6",
                              },
                              {
                                value: "7",
                                label: "7",
                              },
                              {
                                value: "8",
                                label: "8",
                              },
                              {
                                value: "9",
                                label: "9",
                              },
                              {
                                value: "10",
                                label: "10",
                              },
                              {
                                value: "11",
                                label: "11",
                              }, {
                                value: "12",
                                label: "12"
                              },

                            ]}
                          />
                        </Form.Item>
                        {/* //end */}



                      </div>


                    </Card>
                    <Card title='Auto Approval'>
                      <Form.Item
                        label="Leave"
                        initialValue={dataGt?.isLeaveAutoApprove ? true : false}

                        name="isLeaveAutoApprove"

                      >
                        <Switch checked={dataGt?.isLeaveAutoApprove}
                          onChange={() => { onPermissionChange('isLeaveAutoApprove') }}
                          size="small" />

                      </Form.Item>
                      <Form.Item
                        label="WFH"
                        // initialValue={!dataGt ? "" : dataGt?.group_email}
                        initialValue={dataGt?.isWHFAutoApprove ? true : false}

                        name="isWHFAutoApprove"

                      >
                        <Switch checked={dataGt?.isWHFAutoApprove}
                          onChange={() => { onPermissionChangeWFH('isWHFAutoApprove') }}
                          size="small" />

                      </Form.Item>
                      <Form.Item
                        label="Regularization"
                        // initialValue={!dataGt ? "" : dataGt?.group_email}
                        initialValue={dataGt?.isRegularizationAutoApprove ? true : false}

                        name="isRegularizationAutoApprove"

                      >
                        <Switch checked={dataGt?.isRegularizationAutoApprove}
                          onChange={() => { onPermissionChangeREG('isRegularizationAutoApprove') }}
                          size="small" />

                      </Form.Item>
                    </Card>


                    <Card title='Leaves'>

                      <Form.Item
                        label="Birthday Leave  Applicable"
                        initialValue={dataGt.birthDayLeave==false ? false :true}
                        // initialValue={ dataGt?.isRegularizationAutoApprove?true:false}

                        name="isApplicable"

                      >
                        <Checkbox checked={tggelLeave==false ? false :true} onChange={(e) => {

e.target.checked?settggelLeave(true):settggelLeave(false)
                          console.log(`checked = = = = ${e.target.checked}`);
                        }}></Checkbox>

                      </Form.Item>


                      {tggelLeave? 
                       <Form.Item
                          label="Select Number Of Day"
                          name="numOfDay"
                          initialValue={dataGt.numOfDay==0?'---Select---':dataGt.numOfDay}
                          // initialValue={dataGt.numOfDay!=0 ? dataGt?.numOfDay==1?'Half Day':'Full Day': "---Select---" }
                          rules={[
                            {
                              required: true,
                              message: "Please select!",
                            },
                          ]} 
                        >
                          <Select
                            // onChange={handleChange}
                            options={[
                              {
                                value: 0.5,
                                label: "Half Day",
                              },
                              {
                                value: 1,
                                label: "Full Day",
                              }
                          

                            ]}
                          />
                        </Form.Item>:''}
                    
                    </Card>
                    <div className="modal-footer-flex-setting-api">
                      <div
                        className="flex-btn"
                        style={{ display: "flex", gap: "2rem" }}
                      >
                        <Button type="primary" htmlType="submit">
                          Update
                        </Button>
                        <Button
                          onClick={() => {
                            handleCancel1()
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </TabPane>
            </Tabs>
          </Modal>
        </>
      )}
    </div>
  );
};

export default withRouter(UserProfile);
